import { Timestamp } from 'config/firebase';
import { OrderFlowEstimate, TimesSettings } from 'klikni-jadi-shared-stuff';
import moment from 'moment';
import { doc } from 'services/db/firestoreApi';
import { settingsPath } from 'services/settings-path';

export const calculateOrderFlowEstimate = async (region: string, distance: number, preparationTime: number) => {
    const drivingTime = distance * 3;

    const timesSettings = await doc<TimesSettings>(settingsPath(region), 'times_settings');
    let averagePreparationTime = preparationTime || timesSettings.suggestedPreparationTime;

    let createdAt = new Date();
    const createdAtOrder = new Date();

    const estimatedPickupTime = moment(createdAt)
        .add(timesSettings.approvalTime + averagePreparationTime, 'minutes')
        .toDate();

    const estimatedDeliveryTime = moment(createdAt)
        .add(timesSettings.approvalTime + averagePreparationTime + drivingTime + timesSettings.additionalDriverTime, 'minutes')
        .toDate();

    const rounded = ceiling(estimatedDeliveryTime.getMinutes(), 5);
    const roundedEDT = estimatedDeliveryTime;
    roundedEDT.setMinutes(rounded);
    const orderFlowEstimate: OrderFlowEstimate = {
        approvalTime: timesSettings.approvalTime,
        averagePreparationTime,
        drivingTimePartnerCustomer: drivingTime,
        additionalDriverTime: timesSettings.additionalDriverTime,
        suggestedPreparationTime: timesSettings.suggestedPreparationTime,
        estimatedPickupTime: Timestamp.fromDate(estimatedPickupTime),
        roundedEDT: Timestamp.fromDate(roundedEDT),
        estimatedDeliveryTime: Timestamp.fromDate(estimatedDeliveryTime),
    };

    return {
        createdAtOrder,
        orderFlowEstimate,
    };
};

export const ceiling = (number: number, significance: number) => {
    if (significance === 0) {
        return Math.ceil(number);
    } else {
        return Math.ceil(number / significance) * significance;
    }
};
