import { Box, Divider, Paper, Tooltip, Typography, useMediaQuery, useTheme } from '@mui/material';
import { MediumText, NormalText, SmallerText, SmallText } from 'components/atoms/Typography';
import { PersonPinCircleOutlined } from '@mui/icons-material';
import { useRouter } from 'next/router';
import Render from 'components/atoms/Render';
import ResponsiveImage from 'components/atoms/ResponsiveImage';
import useTranslation from 'next-translate/useTranslation';
import { useEffect, useMemo, useRef, useState } from 'react';
import { getVariant } from 'services/typography';
import { LocaleStrings, OrderFlowEstimate, Restaurant } from 'klikni-jadi-shared-stuff';
import { useRestaurantOpenCloseLabels } from 'hooks/useRestaurantOpenCloseLabels';
import { isNumber, upperCase } from 'lodash';
import { days } from 'containers/details/DetailsContent';
import { useDataTranslator } from 'hooks/useDataTranslator';
import FavoriteToggle from 'containers/listings/components/FavoriteToggle';
import { useSelector } from 'react-redux';
import { RootState } from 'redux/reducers';
import deliveryIcon from 'assets/svg/card-info-delivery-icon.svg';
import locationIcon from 'assets/svg/card-info-location-icon.svg';
import clockIcon from 'assets/svg/card-info-clock-icon.svg';
import smileyHappyIcon from 'assets/svg/card-info-smiley-happy.svg';
import { calculateOrderFlowEstimate } from 'services/partners/estimation';
import moment from 'moment';
import WorkingHoursInfo from 'containers/details/components/WorkingHoursInfo';
import { usePartnersDeliveryContext } from 'context/partners-delivery-context';
import Loader from 'react-dots-loader';
import { useLocationContext } from 'context/current-location';

interface IPartnerCardProps {
    hidePropertyInfo?: boolean;
    fullWidth?: boolean;
    hideFavoriteToggle?: boolean;
    isClosed?: boolean;
    partner?: Restaurant;
    isPaused?: boolean;
    bottomMargin?: boolean;
    hideDeliveryFee?: boolean;
    hideDistance?: boolean;
    isNew?: boolean;
}

const PartnerCard = ({
    fullWidth,
    partner,
    isPaused = true,
    bottomMargin,
    hideDeliveryFee,
    hideDistance,
    isNew,
    isClosed = false,
}: IPartnerCardProps) => {
    const [pausedUntilString, setPausedUntilString] = useState<string>('');
    const settingsFilters = useSelector((state: RootState) => state.settingsFilters.data);

    const router = useRouter();
    const { t } = useTranslation('common');
    const { translate } = useDataTranslator();
    const nameRef = useRef<HTMLSpanElement>();
    const nameContainerRef = useRef<HTMLDivElement>();
    const [hasTooltip, setHasTooltip] = useState<boolean>(false);
    const { message, isOpenNow, status } = useRestaurantOpenCloseLabels(partner?.workingHours, partner?.pausedUntil);
    const { data: partnersDelivery } = usePartnersDeliveryContext();
    const {
        data: { locationType },
    } = useLocationContext();

    const [elementWidth, setElementWidth] = useState<number>(0);
    const [estimatedDelivery, setEstimatedDelivery] = useState<number>(0);

    useEffect(() => {
        if (nameRef.current && nameContainerRef.current) {
            if (nameRef.current.offsetWidth + 20 > nameContainerRef.current.offsetWidth) {
                setHasTooltip(true);
            } else setHasTooltip(false);
        }
    }, [elementWidth]);

    // useEffect(() => {
    //     if (partner?.pausedUntil) {
    //         const hours: string =
    //             new Date(partner.pausedUntil?.['_seconds'] * 1000).getHours().toString() ||
    //             new Date(partner.pausedUntil?.seconds * 1000).getHours().toString();
    //         const minutes: string =
    //             new Date(partner.pausedUntil?.['_seconds'] * 1000).getMinutes().toString() ||
    //             new Date(partner.pausedUntil?.seconds * 1000).getMinutes().toString();
    //         const timestampToString: string = hours + minutes;
    //         if (
    //             parseInt(partner?.workingHours?.delivery?.[days[new Date().getDay()]?.day]?.[0]?.to) < parseInt(timestampToString)
    //         ) {
    //             setPausedUntilString(partner?.workingHours?.delivery?.[days[new Date().getDay()]?.day]?.[0]?.from);
    //         } else {
    //             setPausedUntilString(timestampToString);
    //         }
    //     }
    // }, [partner]);

    useEffect(() => {
        calculateOrderFlowEstimate(partner.region, +(partner.distance || 0), partner.averagePreparationTime).then(data => {
            const rounded = moment(data.orderFlowEstimate.roundedEDT.toDate());
            const mins = rounded.diff(moment(), 'minute');
            setEstimatedDelivery(mins);
        });
    }, [partner]);

    const cuisine = useMemo(() => {
        if (settingsFilters?.cuisines) {
            const priorityCuisine = partner.cuisineIds?.find(cuisine => cuisine.priority === 1);
            return settingsFilters.cuisines.find(c => c.id === priorityCuisine?.id)?.name;
        }
        return null;
    }, [partner, settingsFilters]);

    const isLocationAddress = useMemo(() => {
        return locationType !== 'city' && locationType !== 'other';
    }, [locationType]);

    return (
        <>
            <Box
                sx={{
                    backgroundColor: 'white',
                    display: 'flex',
                    flexDirection: 'column',
                    width: '100%',
                    position: 'relative',
                    borderRadius: '10px',
                    // height: 230,
                    // '&:hover': {
                    //     transform: 'scale(1.05)',
                    // },
                    // transition: theme.transitions.create(['transform'], {
                    //     easing: theme.transitions.easing.easeInOut,
                    //     duration: theme.transitions.duration.shorter,
                    // }),
                    boxShadow: '0px 1px 2px 0px rgba(150,150,150,0.5)',
                    // ...((autoWidth ? (index + 1) % numElements !== 0 : false) ? { marginRight: '40px' } : {}),
                    mb: bottomMargin ? 5 : 0,
                    height: isLocationAddress ? 300 : 270,
                }}
            >
                <Box display="flex" flexDirection="column" flex={1} sx={{ width: '100%' }}>
                    <Box
                        sx={{
                            position: 'relative',
                            height: 200,
                        }}
                    >
                        {/* <img
                            src={backgroundShadow}
                            style={{
                                width: `${fullWidth ? '100%' : autoWidth ? `${elementWidth}px` : '220px'}`,
                                height: `${autoWidth ? `${elementWidth}px` : '220px'}`,
                                position: 'absolute',
                                left: 0,
                                top: 0,
                            }}
                        /> */}
                        <ResponsiveImage
                            src={partner.images?.thumbnailUrl}
                            style={{
                                width: '100%',
                                // height: `${autoWidth ? `${elementWidth}px` : '220px'}`,
                                height: 200,
                                maxHeight: '300px',
                                objectFit: 'cover',
                                borderRadius: '10px 10px 0 0',
                            }}
                            options={{
                                width: 1000,
                                height: 600,
                            }}
                            crop
                            alt="restaurant thumbnail"
                        />
                        {/* {partner.images?.logoUrl && (
                            <Box position="absolute" bottom={0} right={0} height={60}>
                                <ResponsiveImage
                                    src={partner.images?.logoUrl}
                                    style={{
                                        width: `60px`,
                                        height: `60px`,
                                        borderRadius: '15px 0 0 0',
                                    }}
                                    options={{
                                        width: 100,
                                        height: 100,
                                    }}
                                    crop
                                    alt="restaurant logo"
                                />
                            </Box>
                        )} */}
                        {/* <Render when={!isOpenNow}>
                            <Box
                                position="absolute"
                                height="100%"
                                width="100%"
                                top={0}
                                sx={{
                                    backgroundColor: 'rgba(0,0,0,0.7)',
                                }}
                                display="flex"
                                justifyContent="center"
                                alignItems="center"
                                borderRadius="10px 10px 0 0"
                            >
                                <Box display="flex" flexDirection="column" alignItems="center">
                                    <Box>
                                        <NormalText semibold sx={{ color: 'white' }}>
                                            {upperCase(t('closed'))}
                                        </NormalText>
                                    </Box>
                                    <Box>
                                        <SmallText sx={{ color: 'white' }}>{message}</SmallText>
                                    </Box>
                                </Box>
                            </Box>
                        </Render> */}
                        <Render when={!isOpenNow || !!partner?.pausedUntil || isClosed}>
                            <Box
                                position="absolute"
                                height="100%"
                                width="100%"
                                top={0}
                                sx={{
                                    backgroundColor: 'rgba(0,0,0,0.7)',
                                }}
                                display="flex"
                                justifyContent="center"
                                alignItems="center"
                                borderRadius="10px 10px 0 0"
                            >
                                <Box display="flex" flexDirection="column" alignItems="center">
                                    <Box>
                                        <NormalText semibold sx={{ color: 'white' }}>
                                            {upperCase(status)}
                                        </NormalText>
                                    </Box>
                                    <Box>
                                        <SmallText sx={{ color: 'white' }}>
                                            {/* {upperCase(t('opensAt'))} {pausedUntilString.replace(/(..)$/, ':$1')} */}
                                            {message}
                                        </SmallText>
                                    </Box>
                                </Box>
                            </Box>
                        </Render>
                        {/* <Render when={!isPaused}>
                            <Box
                                position="absolute"
                                height="100%"
                                width="100%"
                                top={0}
                                sx={{
                                    backgroundColor: 'rgba(0,0,0,0.7)',
                                }}
                                display="flex"
                                justifyContent="center"
                                alignItems="center"
                                borderRadius="10px 10px 0 0"
                            >
                                <Box display="flex" flexDirection="column" alignItems="center">
                                    <Box>
                                        <NormalText semibold sx={{ color: 'white' }}>
                                            {upperCase(t('paused'))}
                                        </NormalText>
                                    </Box>
                                    <Box>
                                        <SmallText sx={{ color: 'white' }}>
                                            {upperCase(t('opensAt'))} {pausedUntilString.replace(/(..)$/, ':$1')}
                                        </SmallText>
                                    </Box>
                                </Box>
                            </Box>
                        </Render> */}
                    </Box>
                    <Box flex={1} minHeight={0} display="flex" flexDirection="column">
                        <Box
                            sx={{
                                width: '100%',
                                pt: 0.8,
                                borderBottom: isLocationAddress ? '1px dashed #C4C4C4' : '',
                            }}
                            display="flex"
                            justifyContent="space-between"
                            alignItems="center"
                            color="black"
                            flex={1}
                            minHeight={0}
                            // fontSize={{ xs: '1.3em', md: '1em' }}
                        >
                            <Box
                                display="flex"
                                flexDirection="column"
                                textAlign="left"
                                justifyContent="flex-start"
                                flex={1}
                                minWidth={0}
                                pr={1}
                                lineHeight="10px"
                            >
                                <Tooltip
                                    title={
                                        hasTooltip ? (
                                            <MediumText
                                                onClick={event => {
                                                    event.stopPropagation();
                                                }}
                                            >
                                                {translate(partner.name)}
                                            </MediumText>
                                        ) : (
                                            ''
                                        )
                                    }
                                    placement="top"
                                    arrow
                                >
                                    <Box
                                        pl={1}
                                        pr={0.5}
                                        sx={{ whiteSpace: 'nowrap', overflow: 'hidden', textOverflow: 'ellipsis' }}
                                        ref={nameContainerRef}
                                    >
                                        <span ref={nameRef}>
                                            <MediumText sx={{ lineHeight: 1.2, fontWeight: 500 }}>
                                                {translate(partner.name)}
                                            </MediumText>
                                        </span>
                                    </Box>
                                </Tooltip>
                                <Box
                                    pl={1}
                                    sx={{
                                        color: '#7C858F',
                                        textAlign: 'left',
                                        whiteSpace: 'nowrap',
                                        overflow: 'hidden',
                                        textOverflow: 'ellipsis',
                                        fontSize: '0.875rem',
                                    }}
                                >
                                    <NormalText>{translate(partner.subtitle)}</NormalText>
                                </Box>
                            </Box>
                            {partner.images?.logoUrl && (
                                <Box mr={1}>
                                    <ResponsiveImage
                                        src={partner.images?.logoUrl}
                                        style={{
                                            width: `44px`,
                                            height: `44px`,
                                            borderRadius: '100%',
                                        }}
                                        options={{
                                            width: 100,
                                            height: 100,
                                        }}
                                        crop
                                        alt="restaurant logo"
                                    />
                                </Box>
                            )}
                            {/* {!hideFavoriteToggle && (
                                <Box pr={2}>
                                    <FavoriteToggle restaurantId={partner.id} />
                                </Box>
                            )} */}
                        </Box>
                        {isLocationAddress && (
                            <Box px={1} display="flex" alignItems="center" py={1} gap={1.5}>
                                {!hideDeliveryFee && (
                                    <Box display="flex" alignItems="center" gap={0.5}>
                                        <img
                                            src={deliveryIcon}
                                            style={{
                                                height: 15,
                                            }}
                                        />
                                        {partnersDelivery?.[partner.id]?.deliveryFee === undefined && (
                                            <Box mt="-4px" width="16px">
                                                <Loader size={4} distance={2} color="#9F9F9F" />
                                            </Box>
                                        )}
                                        {partnersDelivery?.[partner.id]?.deliveryFee >= 0 && (
                                            <SmallText color="#202125a3">
                                                {partnersDelivery?.[partner.id]?.deliveryFee || 0} ден
                                            </SmallText>
                                        )}
                                    </Box>
                                )}
                                {/* {partner?.distance && !hideDistance && (
                                    <Box display="flex" alignItems="center" gap={0.5}>
                                        <img
                                            src={locationIcon}
                                            style={{
                                                height: 15,
                                            }}
                                        />

                                        <SmallText color="#202125a3">{partner.distance} km</SmallText>
                                    </Box>
                                )} */}
                                {!hideDistance && (
                                    <Box display="flex" alignItems="center" gap={0.5}>
                                        <img
                                            src={locationIcon}
                                            style={{
                                                height: 15,
                                            }}
                                        />
                                        {partnersDelivery?.[partner.id]?.distance === undefined && (
                                            <Box mt="-4px" width="16px">
                                                <Loader size={4} distance={2} color="#9F9F9F" />
                                            </Box>
                                        )}
                                        {partnersDelivery?.[partner.id]?.distance >= 0 && (
                                            <SmallText color="#202125a3">
                                                {partnersDelivery?.[partner.id]?.distance || partner?.distance || 0} km
                                            </SmallText>
                                        )}
                                    </Box>
                                )}
                                <Box display="flex" alignItems="center" gap={0.5}>
                                    <img
                                        src={clockIcon}
                                        style={{
                                            height: 15,
                                        }}
                                    />
                                    <SmallText color="#202125a3">
                                        {Math.floor((estimatedDelivery + 0.1) / 10) * 10} -{' '}
                                        {Math.ceil((estimatedDelivery + 0.1) / 10) * 10} min
                                        {/* {estimatedDelivery} min */}
                                    </SmallText>
                                </Box>
                                <Box display="flex" alignItems="center" gap={0.5}>
                                    <img
                                        src={smileyHappyIcon}
                                        style={{
                                            height: 15,
                                        }}
                                    />
                                    <SmallText color="#202125a3">
                                        {partner?.avgRating ? partner.avgRating?.toFixed(1) : ''}
                                    </SmallText>
                                </Box>
                            </Box>
                        )}
                        {/* {cuisine && (
                            <Box ml={1}>
                                <SmallText>
                                    {translate(cuisine)} {t('cuisine', { count: 1 }).toLowerCase()}
                                </SmallText>
                            </Box>
                        )} */}
                        {/* <Box ml={1}> */}
                        {/* <Render when={isOpenNow}>
                                <SmallText
                                    sx={{
                                        color: 'primary.main',
                                    }}
                                >
                                    {t('openNow')}
                                </SmallText>
                            </Render> */}
                        {/* <Render when={!isOpenNow}>
                                <SmallText color="primary">{t('closedNow')}</SmallText>
                            </Render> */}
                        {/* <Render when={isOpenNow}>
                                <SmallText sx={{ mx: 1 }}>-</SmallText>
                                <SmallText>{message}</SmallText>
                            </Render> */}
                        {/* </Box> */}
                        {/* {partner?.distance && (
                            <Box
                                px={1}
                                display="flex"
                                color="#6D7783"
                                style={{ width: `${fullWidth ? '100%' : autoWidth ? `${elementWidth}px` : '250px'}` }}
                            >
                                <Render when={!router.query?.type || router.query.type === 'delivery'}>
                                    <SmallText style={{ display: 'flex', alignItems: 'center' }}>
                                        <PersonPinCircleOutlined />
                                        <Box ml={0.5}>{partner?.distance} km</Box>
                                    </SmallText>
                                </Render>
                                <Render when={router.query?.type === 'takeout'}>
                                    <SmallText style={{ display: 'flex', alignItems: 'center' }}>
                                        <PersonPinCircleOutlined />
                                        <Box ml={0.5}>{partner?.distance} km</Box>
                                    </SmallText>
                                </Render>
                            </Box>
                        )} */}
                    </Box>
                </Box>
                {isNew && (
                    <Box
                        position="absolute"
                        sx={{
                            backgroundColor: '#008ECB',
                            borderRadius: 2,
                            top: 10,
                            left: 10,
                        }}
                        px={2.5}
                        py={0.5}
                    >
                        <NormalText color="white">{t('newLabel')}</NormalText>
                    </Box>
                )}

                {/* <ThumbnailTags tags={{ sponsored: property?.sections?.isSponsored, new: property?.status.isNew }} /> */}
            </Box>
        </>
    );
};

export default PartnerCard;
