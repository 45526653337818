import { East, West } from '@mui/icons-material';
import { Box, Button } from '@mui/material';

interface ILeftArrowProps {
    onClick?: () => void;
    disabled?: boolean;
    left?: boolean;
    right?: boolean;
}

const CircleArrow = ({ onClick, disabled, left, right }: ILeftArrowProps) => {
    return (
        <Button
            sx={{
                width: 40,
                height: 40,
                backgroundColor: '#E5F7FF',
                borderRadius: '100%',
                display: { xs: 'none', md: 'flex' },
                alignItems: 'center',
                justifyContent: 'center',
                minWidth: 0,
                '&:hover:not(:disabled)': {
                    backgroundColor: '#b1daf4',
                },
            }}
            onClick={onClick}
            disabled={disabled}
        >
            {left && (
                <West
                    sx={{
                        fontSize: 22,
                        opacity: disabled ? 0.3 : 1,
                    }}
                    color="primary"
                />
            )}
            {right && (
                <East
                    sx={{
                        fontSize: 22,
                        opacity: disabled ? 0.3 : 1,
                    }}
                    color="primary"
                />
            )}
        </Button>
    );
};

export default CircleArrow;
